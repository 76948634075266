<template>
  <div id="component">
    <div class="filter-panel">
      <input
          v-model="queryParams.name"
          class="cs-input"
          placeholder="请输入园区名称"
          style="
          margin-left: 0;
          margin-right: 30px;
        "
          type="text"
      />
      <button
          class="btn btn-primary btn-sm"
          type="button"
          v-on:click="queryRegionList()"
      >
        查询
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="60">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary btn-sm sticky-right"
                type="button"
                v-on:click="_openAddCommunityModal()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加园区
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>园区名称</th>
            <th>地址</th>
            <th>附近地标</th>
            <th>第三方停车系统</th>
            <th>物业微信收款账号</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="region in regionList" :key="region.code">
            <td>{{ region.name }}</td>
            <td>{{ region.address || "-" }}</td>
            <td>{{ region.landmark || "-" }}</td>
            <td>{{ region.parkingName || '-' }}</td>
            <td>{{ region.merchantId || '-' }}</td>
            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 78px">
                  <li v-if="region.qrCode">
                    <a
                        style="width: 100%"
                        @click="downloadQrCode(region.code, region.name)"
                    >下载</a
                    >
                  </li>
                  <li>
                    <a
                        style="width: 100%"
                        v-on:click="_openEditCommunityModel(region)"
                    >
                      修改
                    </a>
                  </li>
                  <!--                                <li>-->
                  <!--                                    <a-->
                  <!--                                        v-on:click="_openDeleteCommunityModel(region)"-->
                  <!--                                        style="width: 100%"-->
                  <!--                                    >-->
                  <!--                                        删除-->
                  <!--                                    </a>-->
                  <!--                                </li>-->
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination/>
    </div>

    <AddCommunity
        componentName="AddCommunity"
        name="addCommunity"
    ></AddCommunity>
    <EditCommunity
        componentName="EditCommunity"
        name="editCommunity"
    ></EditCommunity>
  </div>
</template>
<script>
import {deleteRegionUrl, downloadQrCodeOssDomain, getRegionQrCodeUrl, queryRegionListUrl,} from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import AddCommunity from "@/components/AddCommunity.vue";
import EditCommunity from "@/components/EditCommunity.vue";
import {STORAGE_KEY} from "@/constant";
import CSTable from "@/components/common/CSTable";

const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));

export default {
  props: {},
  components: {
    CSTable,
    AddCommunity,
    EditCommunity,
    Pagination,
  },
  data() {
    return {
      downloadQrCodeOssDomain,
      userInfo,
      queryParams: {
        code: "",
        name: "",
      },
      regionList: [],
      communityManageInfo: {
        communitys: [],
        total: 0,
        records: 1,
        conditions: {
          name: "",
          cityCode: "",
          communityId: "",
        },
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    window.addEventListener("keydown", this.queryRegionListDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryRegionListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryRegionListDown);
  },
  mounted() {
    this.$vc.on(this.$route.path, "communityManage", "listCommunity", (_param) => {
      this.queryRegionList();
    });
    this.$vc.on(this.$route.path,
        "communityManage",
        "communityManage",
        "notifyArea",
        (_param) => {
        }
    );
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.queryRegionList(pageNo);
    });
    this.queryRegionList();
  },
  methods: {
    queryRegionListDown(e) {
      if (e.keyCode == 13) {
        this.queryRegionList();
      }
    },
    // 下载二维码
    downloadQrCode(regionCode, fileName) {
      this.$fly
          .get(
              getRegionQrCodeUrl,
              {
                regionCode,
              },
              {
                responseType: "blob",
                headers: {
                  notParse: true,
                },
              }
          )
          .then((res) => {
            const link = document.createElement("a");
            const body = document.querySelector("body");

            link.href = window.URL.createObjectURL(res); // 创建对象url
            link.download = fileName;

            // fix Firefox
            link.style.display = "none";
            body.appendChild(link);

            link.click();
            body.removeChild(link);

            window.URL.revokeObjectURL(link.href); // 通过调
          });
    },
    _listCommunitys: function (_page, _rows) {
      this.communityManageInfo.conditions.page = _page;
      this.communityManageInfo.conditions.row = _rows;
      var _param = {
        params: this.communityManageInfo.conditions,
      };
      //发送get请求
      this.$vc.http.get(
          "communityManage",
          "list",
          _param,
          (json, res) => {
            var _communityManageInfo = JSON.parse(json);
            this.communityManageInfo.total = _communityManageInfo.total;
            this.communityManageInfo.records = _communityManageInfo.records;
            this.communityManageInfo.communitys = _communityManageInfo.communitys;
            if (_page === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: this.communityManageInfo.records,
                currentPage: _page,
                pageSize: 10,
              });
            }
          },
          (errInfo, error) => {
            console.log("请求失败处理");
          }
      );
    },
    _openAddCommunityModal: function () {
      this.$vc.emit(this.$route.path, "addCommunity", "openAddCommunityModal", {});
    },
    _openEditCommunityModel: function (_community) {
      this.$vc.emit(this.$route.path, "editCommunity", "openEditCommunityModal", _community);
    },
    // 打开删除弹窗
    _openDeleteCommunityModel: function (region) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除园区吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteRegionUrl, {
                id: region.id,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.queryRegionList();
              });
        },
      });
    },
    // 获取园区列表
    queryRegionList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(queryRegionListUrl, {
            ...this.queryParams,
            pageNo,
            pageSize,
          })
          .then((res) => {
            this.regionList = res.data.datas;
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
          });
    },
  },
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
