<template>
    <CSDialog dialog-title="添加园区" :dialog-visible="dialogVisible"
              @onConfirm="saveCommunityInfo" @onClose="dialogVisible = false"
    >
        <template v-slot:dialog-content>
            <div class="ibox-content" style="padding: 30px;">
                <div>
                    <div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">
                                园区名称
                            </label>
                            <div class="col-sm-8">
                                <input
                                    v-model="
                                                    createRegionParams.name
                                                "
                                    type="text"
                                    placeholder="请输入"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label"
                            >园区地区</label
                            >
                            <div class="col-sm-8">
                                <CSSelect height="40px">
                                    <select
                                        style="width: 130px;font-size: 24px;"
                                        v-model="
                                                        createRegionParams.province
                                                    "
                                        @change="changeProvince"
                                    >
                                        <option value="" disabled>
                                            请选择
                                        </option>
                                        <option
                                            v-for="province in provincesList"
                                            :key="province.code"
                                            :value="province.code"
                                        >
                                            {{ province.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                                <CSSelect
                                    height="40px"
                                    style="margin-left: 20px"
                                >
                                    <select
                                        style="width: 130px;font-size: 24px;"
                                        v-model="
                                                        createRegionParams.city
                                                    "
                                        @change="changeCity"
                                    >
                                        <option value="" disabled>
                                            请选择
                                        </option>
                                        <option
                                            v-for="city in cityList"
                                            :key="city.code"
                                            :value="city.code"
                                        >
                                            {{ city.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                                <CSSelect
                                    height="40px"
                                    style="margin-left: 20px"
                                >
                                    <select
                                        style="width: 130px;font-size: 24px;"
                                        v-model="
                                                        createRegionParams.district
                                                    "
                                    >
                                        <option value="" disabled>
                                            请选择
                                        </option>
                                        <option
                                            v-for="district in districtList"
                                            :key="district.code"
                                            :value="district.code"
                                        >
                                            {{ district.name }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label"
                            >园区地址</label
                            >
                            <div class="col-sm-8">
                                <input
                                    v-model="
                                                    createRegionParams.address
                                                "
                                    type="text"
                                    placeholder="请输入"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label"
                            >附近地标</label
                            >
                            <div class="col-sm-8">
                                <input
                                    v-model="
                                                    createRegionParams.landmark
                                                "
                                    type="text"
                                    placeholder="请输入"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">关联第三方停车系统</label>
                            <div class="col-sm-8">
                                <input type="text" placeholder="请输入" class="form-control"
                                       v-model="createRegionParams.parkingKey"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">关联物业微信收款账号</label>
                            <div class="col-sm-8">
                                <CSSelect height="40px" i-width="40px">
                                    <select style="width: 546px;font-size: 24px;" v-model="createRegionParams.wechatAccountId"
                                            @change="changeWechatAccount">
                                        <option value="">请选择</option>
                                        <option v-for="service in thirdPartyServices"
                                                :value="service.id" :key="service.id"
                                        >
                                            {{ service.merchantId }}
                                        </option>
                                    </select>
                                </CSSelect>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">大数据园区地图文件地址</label>
                            <div class="col-sm-8">
                                <input type="text" placeholder="请输入" class="form-control"
                                       v-model="createRegionParams.mapImgUrl"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CSDialog>
</template>
<script>
import {
    getProvincesUrl,
    getRegionalismUrl,
    createRegionUrl, queryThirdPartyServicesUrl,
} from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

export default {
    props: {},
    components: {CSSelect, CSDialog},
    data() {
        return {
            provincesList: [],
            cityList: [],
            districtList: [],
            dialogVisible: false,
            // 创建园区的参数
            createRegionParams: {
                name: "",
                province: "",
                city: "",
                district: "",
                address: "",
                landmark: "",
                lat: "",
                lon: "",
                photos: "",
                parkingKey: "",
                wechatAccountId: "",
                propertyName: "",
                mapImgUrl: "",
            },
            thirdPartyServices: [],
        };
    },
    mounted() {
        this.$vc.on(this.$route.path, "addCommunity", "openAddCommunityModal", () => {
            this.getProvincesList();
            this.dialogVisible = true;
            this.createRegionParams = {
                name: "",
                province: "",
                city: "",
                district: "",
                address: "",
                landmark: "",
                lat: "",
                lon: "",
                photos: "",
                parkingKey: "",
                wechatAccountId: "",
                propertyName: "",
                mapImgUrl: "",
            };
        });
        this.getThirdPartyService();
    },
    methods: {
        /**
         * 更改选中的微信支付商户号
         * @param {Event} e 事件对象
         * */
        changeWechatAccount(e) {
            const {selectedIndex} = e.target.options;
            this.createRegionParams.propertyName = this.thirdPartyServices[selectedIndex - 1].propertyName;
        },
        // 获取第三方支付商户
        getThirdPartyService() {
            this.$fly.get(queryThirdPartyServicesUrl)
                .then(res => this.thirdPartyServices = res.data ?? []);
        },
        // 获取省级行政区
        getProvincesList() {
            this.$fly.get(getProvincesUrl).then((res) => {
                this.provincesList = res.data;
            });
        },
        // 获取行政区列表
        getRegionalismList(level, parentCode) {
            return this.$fly
                .post(getRegionalismUrl, {
                    level,
                    parentCode,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    switch (level) {
                        case 2:
                            this.cityList = res.data;
                            break;
                        case 3:
                            this.districtList = res.data;
                            break;
                    }
                    return res.data;
                });
        },
        // 监听省市区修改
        async changeProvince() {
            const cityList = await this.getRegionalismList(
                2,
                this.createRegionParams.province
            );
            this.createRegionParams.city = cityList[0].code;
            const districtList = await this.getRegionalismList(
                3,
                this.createRegionParams.city
            );
            this.createRegionParams.district = districtList[0].code;
        },
        async changeCity() {
            const districtList = await this.getRegionalismList(
                3,
                this.createRegionParams.city
            );
            this.createRegionParams.district = districtList[0].code;
        },
        addCommunityValidate() {
            return this.$vc.validate.validate(
                {
                    createRegionParams: this.createRegionParams,
                },
                {
                    "createRegionParams.name": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区名称不能为空",
                        },
                        {
                            limit: "maxin",
                            param: "4,20",
                            errInfo: "园区名称必须在10至20字符之间",
                        },
                    ],
                    "createRegionParams.address": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区地址不能为空",
                        },
                        {
                            limit: "maxLength",
                            param: "200",
                            errInfo: "园区地址不能大于200个字符",
                        },
                    ],
                    "createRegionParams.landmark": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "附近地标不能为空",
                        },
                        {
                            limit: "maxLength",
                            param: "50",
                            errInfo: "附近地标不能大于50个字符",
                        },
                    ],
                    "createRegionParams.province": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区所在省份不能为空",
                        },
                    ],
                    "createRegionParams.city": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区所在城市不能为空",
                        },
                    ],
                    "createRegionParams.district": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区所在区县不能为空",
                        },
                    ],
                }
            );
        },
        saveCommunityInfo: function () {
            /* if (this.addCommunityValidate()) {
                this.$vc.toast(this.$vc.validate.errInfo);
                return;
            } */
            this.$fly
                .post(createRegionUrl, this.createRegionParams)
                .then((res) => {
                    this.dialogVisible = false;
                    this.clearAddCommunityInfo();
                    this.$vc.emit(this.$route.path, "communityManage", "listCommunity", {});
                });
        },
        clearAddCommunityInfo: function () {
            this.createRegionParams = {
                name: "",
                province: "",
                city: "",
                district: "",
                address: "",
                landmark: "",
                lat: "",
                lon: "",
                photos: "",
            };
        },
    },
};
</script>
<style lang="stylus" scoped>
#addCommunityModel
    font-size 24px

    .m-t-none
        font-size 24px
        text-align center

.col-form-label
    color #444
    font-size 24px
    width 160px
    text-align left
    padding-left 0

.form-control
    font-size 24px
    border 1px solid #979797
    height 40px
    box-sizing border-box
    border-radius 4px

    &::placeholder
        color #999
</style>
