
<template>
    <div>
        <CSDialog
            dialogTitle="修改园区"
            :dialogVisible="editCommunityVisible"
            @onConfirm="editCommunity"
            @onClose="editCommunityVisible = false"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;"
                        >园区名称</label
                    >
                    <input
                        v-model="editRegionParams.name"
                        type="text"
                        placeholder="必填，请填写园区名称"
                        style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;"
                    >
                        园区地区
                    </label>
                    <CSSelect
                        height="40px"
                        iWidth="36px"
                    >
                        <select
                            style="width: 130px"
                            v-model="
                                editRegionParams.province
                            "
                            @change="changeProvince"
                        >
                            <option value="" disabled>
                                请选择
                            </option>
                            <option
                                v-for="province in provincesList"
                                :key="province.code"
                                :value="province.code"
                            >
                                {{ province.name }}
                            </option>
                        </select>
                    </CSSelect>
                    <CSSelect
                        height="40px"
                        style="margin-left: 20px"
                        iWidth="36px"
                    >
                        <select
                            style="width: 130px"
                            v-model="
                                editRegionParams.city
                            "
                            @change="changeCity"
                        >
                            <option value="" disabled>
                                请选择
                            </option>
                            <option
                                v-for="city in cityList"
                                :key="city.code"
                                :value="city.code"
                            >
                                {{ city.name }}
                            </option>
                        </select>
                    </CSSelect>
                    <CSSelect
                        height="40px"
                        style="margin-left: 20px"
                        iWidth="36px"
                    >
                        <select
                            style="width: 130px"
                            v-model="
                                editRegionParams.district
                            "
                        >
                            <option value="" disabled>
                                请选择
                            </option>
                            <option
                                v-for="district in districtList"
                                :key="district.code"
                                :value="district.code"
                            >
                                {{ district.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <div style="margin-bottom: 23px;">
                    <label
                        style="width: 280px;"
                    >
                        园区地址
                    </label>
                    <input
                        v-model="
                            editRegionParams.address
                        "
                        style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        type="text"
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;">
                        附近地标
                    </label>
                    <input
                        v-model="
                            editRegionParams.landmark
                        "
                        type="text"
                        style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                        placeholder="请输入"
                    />
                </div>

                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;">关联第三方停车系统</label>
                    <input type="text" placeholder="请输入" readonly disabled
                        :value="parkingName" style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;">关联物业微信收款账号</label>
                    <input type="text" placeholder="请输入" readonly disabled
                        :value="merchantId" style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 280px;">大数据园区地图文件地址</label>
                    <input type="text" placeholder="请输入" readonly disabled
                           :value="mapImgUrl" style="width: 560px; height: 40px; border: 1px solid #999; border-radius: 4px; padding-left: 10px;"
                    />
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

import {
    editRegionUrl,
    getProvincesUrl,
    getRegionalismUrl,
} from "@/requestUrl";
export default {
    props: {},
    components: {
        CSSelect,
        CSDialog
    },
    data() {
        return {
            provincesList: [],
            cityList: [],
            districtList: [],
            editRegionParams: {
                id: 1, //园区ID，必填
                name: "",
                province: "",
                city: "",
                district: "",
                address: "",
                landmark: "",
                lat: 0.0, //纬度，选填
                lon: 0.0, //经度，选填
                photos: "", //图片url，选填
            },
            merchantId: '',
            parkingName: '',
            mapImgUrl: '',
            editCommunityVisible: false,
        };
    },
    mounted() {
        this.$vc.on(this.$route.path,
            "editCommunity",
            "openEditCommunityModal",
            async (params) => {
                this.merchantId =  params.merchantId;
                this.parkingName = params.parkingName;
                this.mapImgUrl = params.mapImgUrl;
                await this.getProvincesList();
                this.getRegionalismList(2, params.provinceCode);
                this.getRegionalismList(3, params.cityCode);
                this.editRegionParams = {
                    id: params.id,
                    name: params.name,
                    province: params.provinceCode,
                    city: params.cityCode,
                    district: params.distCode,
                    address: params.address,
                    landmark: params.landmark,
                    lat: params.lat, //纬度，选填
                    lon: params.lon, //经度，选填
                    photos: "", //图片url，选填
                };
                this.editCommunityVisible = true;
                //this.editCommunityInfo.communityId = this.$vc.getCurrentRegion().communityId;
            }
        );
    },
    methods: {
        // 获取省级行政区
        getProvincesList() {
            return this.$fly.get(getProvincesUrl).then((res) => {
                this.provincesList = res.data;
                return res.data;
            });
        },
        // 获取行政区列表
        getRegionalismList(level, parentCode) {
            return this.$fly
                .post(getRegionalismUrl, {
                    level,
                    parentCode,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    switch (level) {
                        case 2:
                            this.cityList = res.data;
                            break;
                        case 3:
                            this.districtList = res.data;
                            break;
                    }
                    return res.data;
                });
        },
        // 监听省市区修改
        async changeProvince() {
            const cityList = await this.getRegionalismList(
                2,
                this.editRegionParams.province
            );
            this.editRegionParams.city = cityList[0].code;
            const districtList = await this.getRegionalismList(
                3,
                this.editRegionParams.city
            );
            this.editRegionParams.district = districtList[0].code;
        },
        async changeCity() {
            const districtList = await this.getRegionalismList(
                3,
                this.editRegionParams.city
            );
            this.editRegionParams.district = districtList[0].code;
        },
        editCommunityValidate: function () {
            return this.$vc.validate.validate(
                {
                    editCommunityInfo: this.editCommunityInfo,
                },
                {
                    "editCommunityInfo.name": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区名称不能为空",
                        },
                        {
                            limit: "maxin",
                            param: "4,20",
                            errInfo: "园区名称必须在4至20字符之间",
                        },
                    ],
                    "editCommunityInfo.address": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区地址不能为空",
                        },
                        {
                            limit: "maxLength",
                            param: "200",
                            errInfo: "园区地址不能大于200个字符",
                        },
                    ],
                    "editCommunityInfo.nearbyLandmarks": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "附近地标不能为空",
                        },
                        {
                            limit: "maxLength",
                            param: "50",
                            errInfo: "园区附近地标不能大于50个字符",
                        },
                    ],
                    "editCommunityInfo.cityCode": [
                        {
                            limit: "maxLength",
                            param: "12",
                            errInfo: "园区城市编码不能大于4个字符",
                        },
                    ],
                    "editCommunityInfo.mapX": [
                        {
                            limit: "maxLength",
                            param: "20",
                            errInfo: "园区城市编码不能大于4个字符",
                        },
                    ],
                    "editCommunityInfo.mapY": [
                        {
                            limit: "maxLength",
                            param: "20",
                            errInfo: "园区城市编码不能大于4个字符",
                        },
                    ],
                    "editCommunityInfo.communityId": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "园区ID不能为空",
                        },
                    ],
                }
            );
        },
        editCommunity: function () {
            this.$fly.post(editRegionUrl, this.editRegionParams).then((res) => {
                this.editCommunityVisible = false;
                this.$vc.emit(this.$route.path, "communityManage", "listCommunity", {});
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
#editCommunityModel
    font-size 24px
    .m-t-none
        font-size 24px
        text-align center
.col-form-label
    color #444
    font-size 24px
    width 160px
    text-align left
    padding-left 0
.form-control
    font-size 24px
    border 1px solid #979797
    height 40px
    box-sizing border-box
    border-radius 4px
</style>
